import React, {
    useCallback, useEffect, useState
} from 'react';
import { Div, svgSprite } from '../../../hybrid/wrappers';

const UpBtnNews = ({ styles }) => {
    const goTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const [show, setShow] = useState(false)
    const scroll = useCallback(() => {
        setShow(window.pageYOffset > 100)
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', scroll)
        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [scroll])
    return (
        <>
            {
                show && <Div
                    data-uitest='383_uitest' onClick={goTop}
                    effects={{hover: {transform: 'scale(1.1)'}}}
                    styles={{
                        position: 'fixed',
                        bottom: '120px',
                        right: '22px',
                        display: 'flex',
                        'justify-content': 'center',
                        'align-items': 'center',
                        'z-index': '101',
                        width: '64px',
                        height: '64px',
                        background: styles.variable.whiteColor,
                        'border-radius': '50%',
                        cursor: 'pointer',
                        border: `1px solid ${styles.variable.blueBackgroundLight}`,
                        'box-sizing': 'border-box',
                        'box-shadow': `0px 16px 30px ${styles.variable.blackExtraTransparentColor}`,
                        transition: '0.2s linear',
                        ...styles?.btnPosition
                    }}
                >
                    {svgSprite('arrow-up', {
                        width: 13, height: 16, fill: styles.variable.darkColor
                    })}
                </Div>
            }
        </>
    );
};

export default UpBtnNews;