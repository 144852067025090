import React, { useContext, useEffect } from 'react';
import getStyles from '../../project/styles/layouts-styles/landing-layout-styles';
import Styles from '../../common/decorators/Styles';
import SeoData from '../../widgets/seoData/SeoData';
import T, { t } from '../../common/components/T';
import UserContext from '../../common/utils/getContext';
import LandingHeader from './components/LandingHeader';
import LandingFooter from './components/LandingFooter';
import UpBtnNews from '../../views/news/components/UpBtnNews';
import SingleUnderFooter from '../single/components/SingleUnderFooter';
import { LangContext } from '../../common/decorators/Language';

const Landing = ({ styles, children, addBackground, seoData, title, isLandingPage }) => {
    const {
        userToken, menu, lang
    } = useContext(UserContext)
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};
    return (
        <>
            <UpBtnNews styles={styles} />
            <SeoData data={seoData} title={title || t('main-page-title-seo-update', 'Главная', 'all', translation)}/>
            <LandingHeader isLandingPage={isLandingPage} addBackground={addBackground} styles={!addBackground ? styles : { ...styles, headerWrapper: styles.headerWrapperWithBg }} userToken={userToken} menu={menu} lang={lang}/>
            {children}
            <LandingFooter styles={styles} lang={lang}/>
            {/* <SingleUnderFooter customStyles={{ footerDown: styles.footerDown }} /> */}
        </>
    )
}
export default Styles(Landing, getStyles)
