import { useContext, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/widget-styles/timer';
import UserContext from '../../common/utils/getContext';
import { Div, P } from '../../hybrid/wrappers';
import T from '../../common/components/T';

const Timer = (props) => {
    const { time } = useContext(UserContext)
    const { styles } = props;
    const [timeToEnd, setTimeToEnd] = useState({});
    const deadLine = useMemo(() => Date.UTC(2023, 5, 18, 20, 0, 0), []);
    const interval = useRef(null);

    const stopTimer = useCallback(() => {
        clearInterval(interval.current);
        interval.current = null;
    }, [])

    const startTimer = useCallback(() => {
        interval.current = setInterval(() => {
            let result = true;
            result = getTimeRemaining(new Date(deadLine));

            if (!result) {
                stopTimer()
            }
        }, 1000);
    }, [deadLine]);

    function getTimeRemaining(endtime){
        function transformValue(value) {
            if (String(value).length === 1) {
                return `0${value}`;
            }
            return String(value);
        }
        const t = Date.parse(endtime) - Date.parse(new Date(time()));
        const seconds = Math.floor( (t/1000) % 60 );
        const minutes = Math.floor( (t/1000/60) % 60 );
        const hours = Math.floor( (t/(1000*60*60)) % 24 );
        const days = Math.floor( t/(1000*60*60*24) );
        if (t <= 0) {
            setTimeToEnd({
                'total': '00',
                'days': '00',
                'hours': '00',
                'minutes': '00',
                'seconds': '00'
            });
            return;
        }
        setTimeToEnd({
            'total': t,
            'days': transformValue(days),
            'hours': transformValue(hours),
            'minutes': transformValue(minutes),
            'seconds': transformValue(seconds)
        });
        return true;
    }

    useEffect(() => {
        if (deadLine) {
            getTimeRemaining(new Date(deadLine))
            startTimer(deadLine);
        }
        return () => {
            clearInterval(interval.current); 
        }
    }, [deadLine]);

    if (!deadLine || +timeToEnd?.total <= 0) return <></>;

    return (
        <Div styles={styles.timer}>
            <P styles={styles.timerLabel}><T textName='to-start-text' defaultStr='До старта' page='all' />:</P>
            <Div styles={styles.timerContent}>
                <P styles={styles.timerItem}>{timeToEnd?.days}</P>:
                <P styles={styles.timerItem}>{timeToEnd?.hours}</P>:
                <P styles={styles.timerItem}>{timeToEnd?.minutes}</P>:
                <P styles={styles.timerItem}>{timeToEnd?.seconds}</P>
            </Div>
        </Div>
    )
};

Timer.propTypes = {
    styles: PropTypes.object,
    deadLine: PropTypes.any
}

export default Styles(Timer, getStyles);