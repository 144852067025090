import React, {
    useState, useContext, useMemo
} from 'react';
import UserContext from '../../common/utils/getContext';
import {
    Div, P, svgSprite
} from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/lang-switcher-styles';
import Styles from '../../common/decorators/Styles';
import {useRouter} from 'next/router'

const LangSwitcher = ({
    styles, placement = 'bottom', single = true, isMobileVersion = false, isDark = false
}) => {
    const router = useRouter()
    const {
        lang, activeLangs, setState
    } = useContext(UserContext);
    const activeLanguage = activeLangs && activeLangs?.find((language) => language.alias === (router?.query?.lang || 'en')) || null;
    const [showLangsDesktop, setShowLangsDesktop] = useState(false);

    // const langsForMenu = useMemo(() => activeLangs?.filter((l) => l.active && l.show && l?.alias !== 'hu'), [activeLangs])
    const langsForMenu = useMemo(() => {
        const filteredLangs = activeLangs?.filter((l) => l.active && l.show && l?.alias !== 'hu');
        return filteredLangs.sort((a, b) => a.alias.localeCompare(b.alias));
    }, [activeLangs]);

    if (!activeLanguage) return null;

    return (
        <Div styles={styles.menuLangMargin}>
            <Div styles={isMobileVersion ? styles.menuLangsWrapper : styles.menuLangsWrapperMobile}>
                {
                    showLangsDesktop && langsForMenu.length ? <Div data-uitest='639_uitest' onClick={() => {
                        setShowLangsDesktop(false)
                    }} styles={styles.menuLangsBackdrop}/> : null
                }
                <Div
                    styles={isDark ?
                        {
                            ...styles.menuLangSwitcherDesktopDark,
                            ...!single ? { 'border-radius': '12px 0 0 12px' } : {}
                        } : {
                            ...styles.menuLangSwitcherDesktop,
                            ...!single ? { 'border-radius': '12px 0 0 12px' } : {}
                        }}
                    data-uitest='640_uitest' onClick={() => {
                        setShowLangsDesktop((pre) => !pre)
                    }}
                    effects={{ hover: styles.menuLangSwitcherDesktopHover }}
                >
                    <Div styles={styles.menuLangSwitcherLangFlag}>
                        <P styles={styles.menuLangSwitcherFlag}>
                            {svgSprite('new-globe', { fill: 'inherit' })}
                        </P>
                        <P styles={styles.menuLangSwitcherNameDesktop}>
                            {
                                activeLanguage?.alias || router?.query?.lang
                            }
                        </P>
                    </Div>
                </Div>

                {
                    showLangsDesktop && langsForMenu.length ?
                        <Div
                            styles={{ ...styles.modalLangListWrap, ...placement === 'left' ? styles.modalLangListWrapLeft : {}, ...placement === 'isMobileLanding' ? {left: '0', right: 'inherit'} : {} }}>
                            {/* {svgSprite('arrow-dropdown-up', { style: { ...styles.modalLangListArrow, ...placement === 'left' ? styles.modalLangListArrowLeft : {} } })} */}
                            <Div styles={styles.modalLangListDesktop}>
                                {
                                    langsForMenu.map((language, index) => {
                                        return (
                                            <Div
                                                styles={
                                                    language?.alias === lang ? {
                                                        ...styles.modalLangListItemDesktop, color: 'black', ...index === 0 ? styles.modalLangListItemFirst : {}, ...index === langsForMenu.length - 1 ? styles.modalLangListItemLast : {}
                                                    } : {
                                                        ...styles.modalLangListItemDesktop, ...index === 0 ? styles.modalLangListItemFirst : {}, ...index === langsForMenu.length - 1 ? styles.modalLangListItemLast : {}
                                                    }
                                                }
                                                key={`langItem${language.alias}`}
                                                data-uitest='641_uitest' onClick={() => {
                                                    setState('lang', language.alias)
                                                    setShowLangsDesktop(false)
                                                }}
                                                effects={{ hover: styles.modalLangListItemHover }}
                                            >
                                                <P styles={styles.menuLangSwitcherListFlag}>
                                                    {
                                                        language?.alias
                                                    }
                                                    {/* {
                                                        language?.flag && language?.flag[0] && language?.flag[0].preview ?
                                                            <Img styles={styles.menuLangSwitcherLangFlagImgDesktop}
                                                                src={language.flag[0].preview}/> : null
                                                    } */}
                                                </P>
                                                <P styles={styles.menuLangSwitcherInfoDesktop}>
                                                    {language.name}
                                                </P>
                                            </Div>
                                        )
                                    })
                                }
                            </Div>
                        </Div>
                        : null
                }
            </Div>
        </Div>
    )
}
export default Styles(LangSwitcher, getStyles);