import React from 'react';
import T from '../../../common/components/T';
import { SITE_URL } from "../../../common/utils/utils";
import {
    Div, svgSprite, FakeLink, BlankLink, P
} from '../../../hybrid/wrappers';
import { navigate } from '../../../common/utils/router'
import styleComp from './footer.module.scss'

const LandingFooter = ({ styles, lang }) => {
    // const socialLinks = [
    //     {
    //         id: '01',
    //         name: 'landing-insta',
    //         link: 'https://instagram.com'
    //     },
    //     {
    //         id: '02',
    //         name: 'landing-twitter',
    //         link: 'https://twitter.com'
    //     },
    //     {
    //         id: '03',
    //         name: 'landing-linked',
    //         link: 'https://linkedin.com'
    //     },
    //     {
    //         id: '04',
    //         name: 'landing-facebook',
    //         link: 'https://facebook.com'
    //     },
    //     {
    //         id: '05',
    //         name: 'landing-youtube',
    //         link: 'https://youtube.com'
    //     }
    // ]
    const currentYear = new Date().getFullYear();
    return (
        <Div styles={{ position: 'relative', 'z-index': '100' }}>
            <Div className={styleComp.footerHorizontalLine}/>
            <Div styles={ styles.footerWrapper }>
                <Div className={styleComp.footerLinesWrapper}>
                    <Div className={styleComp.footerLines}/>
                    <Div styles={ styles.footerUpperWrapper }>
                        <FakeLink follow={true} href={`/${lang}`} styles={ styles.logoLinkFooter }>
                            {svgSprite('index-logo-inc', styles.logoSvgFooter)}
                        </FakeLink>
                        {/* <Div styles={styles.socialWrapper}>
                            {socialLinks.map(item => {
                                return (
                                    <Div
                                        key={item.id}
                                        styles={ styles.socialLinkWrapper }
                                        effects={{ hover: styles.socialLinkWrapperHover }}
                                        onClick={() => {
                                            window.open(item.link, '_blank')
                                        }}
                                    >
                                        {svgSprite(item.name, styles.socialLinkSvg)}
                                    </Div>
                                )
                            })}
                        </Div> */}
                    </Div>
                    <Div styles={ styles.footerLower }>
                        <Div styles={ styles.companyWrapper }>
                            <P styles={ styles.footerCompany }>
                                <T textName='company-name-label12' vars={{ currentYear: currentYear }} defaultStr='© ${currentYear} «eternity.inc»' page='/'/>
                            </P>
                        </Div>
                        {/* <Div styles={{ ...styles.footerCompany, ...styles.lifeupBlock }}>
                            <T textName='lifeup-landing-label' defaultStr='ETERNITY Inc uses services of LifeUp International OÜ as a payment agent provider.' page='all'/>
                        </Div> */}
                        <Div styles={ styles.footerLowerAgreements }>
                            <P styles={styles.footerLinksWrapper}>
                                <BlankLink styles={{ ...styles.agreementLink, ...styles.agreementLinkMargin }} effects={{ hover: styles.agreementLinkHover }} title={<T textName='agreement-labelnew' defaultStr='Пользовательское соглашение' page='/'/>} href={`${SITE_URL}/${lang}/page/agreement`} target={'_blank'}/>
                            </P>
                            <P styles={styles.footerLinksWrapper}>
                                <BlankLink styles={{ ...styles.agreementLink, ...styles.agreementLinkMargin }} effects={{ hover: styles.agreementLinkHover }} title={<T textName='privacy-labelnew' defaultStr='Политика конфиденциальности' page='/'/>} href={`${SITE_URL}/${lang}/page/privacy_policy`} target={'_blank'}/>
                            </P>
                            <P styles={styles.footerLinksWrapper}>
                                <BlankLink styles={{ ...styles.agreementLink, ...styles.agreementLinkMargin }} effects={{ hover: styles.agreementLinkHover }} title={<T textName='aml-labelnew' defaultStr='AML' page='/'/>} href={`${SITE_URL}/${lang}/page/aml`} target={'_blank'}/>
                            </P>
                            <P styles={styles.footerLinksWrapper}>
                                <BlankLink styles={{ ...styles.agreementLink, 'margin-bottom': '0' }} effects={{ hover: styles.agreementLinkHover }} title={<T textName='impressum-labelnew' defaultStr='Impressum' page='/'/>} href={`${SITE_URL}/${lang}/page/impressum`} target={'_blank'}/>
                            </P>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    )
}
export default LandingFooter