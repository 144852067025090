import { media } from "../../../common/utils/utils";
import uikit from "../../uikit/styles";

const stylesheets = (width, height, variable = {}) => ({
    timer: media(width, height, 'w', {
        768: {
            ...uikit.flexRow,
            ...uikit.alignItemsEnd,
            ...uikit.justifyContentBetween,
            'background-color': variable.yellowColor,
            padding: '12px 24px',
            color: variable.blueColor,
            'font-weight': '700',
            'white-space': 'nowrap',
            border: `6px solid ${variable.blueColor}`,
            ...uikit.h5
        },
        0: {
            ...uikit.flexRow,
            ...uikit.alignItemsEnd,
            ...uikit.justifyContentBetween,
            'background-color': variable.yellowColor,
            padding: '6px 12px',
            color: variable.blueColor,
            'white-space': 'nowrap',
            border: `3px solid ${variable.blueColor}`,
            ...uikit.h6,
            'font-weight': '700'
        }
    }),
    timerItem: media(width, height, 'w', {
        768: {
            width: '36px',
            ...uikit.flexRow,
            ...uikit.justifyContentCenter,
            ...uikit.alignItemsCenter
        },
        0: {
            width: '30px',
            ...uikit.flexRow,
            ...uikit.justifyContentCenter,
            ...uikit.alignItemsCenter
        }
    }),
    timerLabel: media(width, height, 'w', {
        768: {
            ...uikit.mr4
        },
        0: {
            ...uikit.mr1
        }
    }),
    timerContent: {
        ...uikit.flexRow,
        ...uikit.alignItemsCenter,
    }
});

export default stylesheets;