import React from 'react';
import T from '../../../common/components/T';
import { Div, svgSprite } from '../../../hybrid/wrappers';
import LangSwitcherDesktop from '../../../widgets/langSwitcher/LangSwitcherDesktop';
import AuthenticationButtons from './AuthenticationButtons';
import SingleMenu from '../../../widgets/menu/single/SingleMenu';

const LandingMobileMenu = ({
    styles, setShowMenu, active, lang, userToken
}) => {
    return (
        <Div styles={ styles.mobileMenuWrapper }>
            <Div styles={ styles.mobileMenuBorder }>
                <Div styles={ styles.mobileMenuHeader }>
                    <Div styles={ styles.langSwitcherWrapperMobile }>
                        <LangSwitcherDesktop placement={'isMobileLanding'}/>
                    </Div>
                    <Div
                        styles={{
                            display: 'flex',
                            'align-items': 'center',
                            color: 'white',
                            fill: 'white',
                            cursor: 'pointer'
                        }}
                        effects={{ hover: { color: '#FAFF1B', fill: 'rgba(250, 255, 27, 1)' } }}
                        onClick={() => setShowMenu(pre => !pre)}
                    >
                        <Div styles={ styles.closeLabelText }>
                            <T textName='close-menu-label' defaultStr='Закрыть' page='all'/>
                        </Div>
                        <Div styles={{ height: '24px' }}>
                            {svgSprite('new-close', {
                                fill: styles.variable.yellowColor,
                                width: '24px',
                                height: '24px'
                            })}
                        </Div>
                    </Div>
                </Div>
                <Div styles={ styles.mobileMenuMain }>
                    <Div styles={styles.mobileMenuLinks }>
                        <SingleMenu
                            styles={styles}
                            active={active}
                        />
                    </Div>
                    <Div styles={ styles.authButtons }>
                        <AuthenticationButtons styles={styles} userToken={userToken} lang={lang}/>
                    </Div>
                </Div>
            </Div>
        </Div>
    )
}
export default LandingMobileMenu