import React, { useState, useEffect, useMemo } from 'react';
import {
    Div, svgSprite, FakeLink, Img
} from '../../../hybrid/wrappers';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import LangSwitcherDesktop from '../../../widgets/langSwitcher/LangSwitcherDesktop';
import AuthenticationButtons from './AuthenticationButtons';
import LandingMobileMenu from './LandingMobileMenu';
import SingleMenu from '../../../widgets/menu/single/SingleMenu';
import { useRouter } from 'next/router';

import bgImage from '../../../public/src/img/landing/hero/hero-bg.png';
import Timer from '../../../widgets/timer/Timer';

const LandingHeader = ({
    styles, userToken, lang, addBackground, isLandingPage
}) => {
    const active = useRouter().route.replace('/[lang]', '');
    const [isMobile, setIsMobile] = useState(false);
    const [showMenu, setShowMenu] = useState(false)
    const windowDimensions = useWindowDimensions();

    useEffect(() => {
        if (windowDimensions < 993) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowDimensions]);



    return (
        <Div styles={ styles.headerWrapper }>
            {addBackground ? <Img src={bgImage?.src} styles={styles.headerBg} /> : null}
            <Div styles={ styles.logoAndMenuWrapper }>
                <Div styles={ styles.logoWrapper }>
                    {
                        isLandingPage ? <Div styles={styles.headerTimer}><Timer /></Div> : null
                    }
                    <FakeLink follow={true} href={`/${lang}`} styles={ styles.logoLink }>
                        {svgSprite('index-logo-inc', styles.logoSvg)}
                    </FakeLink>
                </Div>
                <Div styles={ styles.menuWrapper}>
                    <SingleMenu
                        styles={styles}
                        isMobile={isMobile}
                        active={active}
                    />
                </Div>
            </Div>
            <Div styles={ styles.langAndButtonsWrapper }>
                <LangSwitcherDesktop />
                <AuthenticationButtons styles={styles} userToken={userToken} lang={lang}/>
            </Div>
            <Div
                styles={ styles.burgerMenu }
                onClick={() => setShowMenu(pre => !pre)}
            >
                {svgSprite('new-burger', { fill: 'white' })}
            </Div>
            {showMenu ?
                <LandingMobileMenu
                    setShowMenu={setShowMenu}
                    styles={styles}
                    active={active}
                    lang={lang}
                    userToken={userToken}
                />
                : null}
        </Div>
    )
}
export default LandingHeader