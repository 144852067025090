import React from 'react';
import T from '../../../common/components/T';
import { Div, svgSprite } from '../../../hybrid/wrappers';
import { navigate } from '../../../common/utils/router';

const AuthenticationButtons = ({
    styles, userToken, lang
}) => {
    return (
        <Div styles={styles.authenticationButtonsWrapper}>
            {!userToken ?
                <Div styles={ styles.unAuthenticatedButtons }>
                    <Div
                        styles={{ ...styles.signInButton, ...styles.authenticatedText }}
                        effects={{ hover: { 'color': 'rgba(250, 255, 27, 1)', cursor: 'pointer' } }}
                        onClick={() => navigate('/[lang]/authentication/[type]', '/authentication/login', lang)}
                    >
                        <T textName='sing-in-lable' defaultStr='Вход' page='/'/>
                    </Div>
                    {/* <Div
                        styles={{ ...styles.authenticatedText, ...styles.button51 }}
                        effects={{ hover: { background: 'white', cursor: 'pointer' }, active: { background: 'rgba(255, 255, 255, 0.8)', cursor: 'pointer' } }}
                        onClick={() => navigate('/[lang]/authentication/[type]', '/authentication/register', lang)}
                    >
                        <T textName='register-lable' defaultStr='Регистрация' page='/'/>
                    </Div> */}
                </Div>
                :
                <Div
                    styles={{ ...styles.authenticatedUser, ...styles.button51 }}
                    effects={{ hover: { background: 'white', cursor: 'pointer' }, active: { background: 'rgba(255, 255, 255, 0.8)', cursor: 'pointer' } }}
                    onClick={() => navigate('/[lang]/office', '/office', lang)}
                >
                    {/* <Div styles={ styles.authenticatedUserIcon }>
                        {svgSprite('user-case-image')}
                    </Div> */}
                    <Div styles={ styles.authenticatedText }>
                        <T textName='office-lable' defaultStr='Кабинет' page='/'/>
                    </Div>
                </Div>
            }
        </Div>
    )
}
export default AuthenticationButtons