import { media } from '../../../common/utils/utils';
import { landingBg } from '../../uikit/color-variables';
// import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    headerWrapper: media(width, height, 'w', {
        1320: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            padding: '32px',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            'z-index': '1000',
            width: '100%'
        },
        768: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            padding: '16px 32px',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            'z-index': '1000',
            width: '100%'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            padding: '8px 16px',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            'z-index': '1000',
            width: '100%'
        }
    }),
    headerTimer: media(width, height, 'w', {
        768: {
            position: 'absolute',
            left: '100%',
            top: '100%'
        },
        0: {
            position: 'absolute',
            left: '0%',
            top: '100%'
        }
    }),
    btnPosition: {
        bottom: '85px',
        right: '16px'
    },
    headerBg: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        'z-index': -1,
        'object-fit': 'cover',
        'object-position': 'top'
    },
    headerWrapperWithBg: media(width, height, 'w', {
        1320: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            padding: '32px',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            'z-index': '1000',
            width: '100%',
            // background: landingBg
        },
        768: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            padding: '16px 32px',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            'z-index': '1000',
            width: '100%',
            // background: landingBg
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            padding: '8px 16px',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            'z-index': '1000',
            width: '100%',
            // background: landingBg
        }
    }),
    logoWrapper: media(width, height, 'w', {
        768: {
            width: '228px',
            padding: '16px 0',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            background: 'white',
            'margin-right': '64px',
            'margin-left': '-32px',
            position: 'relative'
        },
        0: {
            width: '130px',
            padding: '8px 0',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            background: 'white',
            'margin-left': '-16px',
            position: 'relative'
        }
    }),
    logoSvg: media(width, height, 'w', {
        768: {
            width: '236px',
            height: '54px'
        },
        0: {
            width: '140px',
            height: '32px'
        }
    }),
    logoLink: media(width, height, 'w', {
        768: {
            height: '54px',
            display: 'block'
        },
        0: {
            height: '32px',
            display: 'block'
        }
    }),
    logoLinkFooter: {
        height: '54px',
        display: 'block'
    },
    logoSvgFooter: {
        width: '164px',
        height: '54px'
    },
    wrapperItem: media(width, height, 'w', {
        1320: { 'margin-right': '32px' },
        0: { 'margin-right': '0' }
    }),
    mobileMenuMain: { 'padding-top': '100px' },
    itemMenuStyle: media(width, height, 'w', {
        1320: {
            color: 'white',
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '600',
            'font-size': '16px',
            'line-height': '20px'
        },
        0: {
            color: 'white',
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '600',
            'font-size': '24px',
            'line-height': '29px'
        }
    }),
    officeMenuItemTextHover: { color: 'rgba(250, 255, 27, 1)' },
    officeMenuItemTextP: media(width, height, 'w', {
        1320: {
            'border-bottom': '2px solid transparent',
            padding: '8px 0'
        },
        0: { 'padding-bottom': '48px' }
    }),
    officeMenuItemTextHover: {
        'border-bottom': '2px solid rgba(250, 255, 27, 1)',
        color: 'rgba(250, 255, 27, 1)',
        padding: '8px 0'
    },
    authenticatedUser: {
        display: 'flex',
        'align-items': 'center'
    },
    authenticatedUserIcon: {
        height: '24px',
        'margin-right': '12px'
    },
    authenticatedText: {
        'font-family': 'Gilroy, sans-serif',
        'font-weight': '600',
        'font-size': '16px',
        'line-height': '20px'
    },
    button51: media(width, height, 'w', {
        1320: {
            height: '51px',
            display: 'inline-flex',
            'align-items': 'center',
            padding: '0 32px',
            'border-radius': '80px',
            background: '#FAFF1B',
            'margin-bottom': '0'
        },
        0: {
            height: '46px',
            display: 'inline-flex',
            'align-items': 'center',
            padding: '0 24px',
            'border-radius': '80px',
            background: '#FAFF1B',
            'margin-bottom': '24px'
        }
    }),
    unAuthenticatedButtons: media(width, height, 'w', {
        1320: {
            display: 'flex',
            'align-items': 'center',
            'flex-direction': 'row'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'flex-direction': 'column-reverse',
            'align-items': 'center'
        }
    }),
    signInButton: media(width, height, 'w', {
        1320: {
            'margin-right': '32px',
            color: '#fff',
            'border-bottom': '1px solid transparent'
        },
        0: {
            'margin-right': '0px',
            color: '#fff',
            'border-bottom': '1px solid transparent'
        }
    }),
    langAndButtonsWrapper: media(width, height, 'w', {
        1320: {
            display: 'flex',
            'align-items': 'center'
        },
        0: { display: 'none' }
    }),
    menuWrapper: media(width, height, 'w', {
        1320: {
            display: 'flex',
            'align-items': 'center'
        },
        0: { display: 'none' }
    }),
    burgerMenu: media(width, height, 'w', {
        1320: { display: 'none' },
        0: {
            display: 'flex',
            'align-items': 'center',
            cursor: 'pointer'
        }
    }),
    authenticationButtonsWrapper: media(width, height, 'w', {
        1320: { 'margin-left': '26px' },
        0: {
            'margin-left': '0',
            'text-align': 'center'
        }
    }),
    logoAndMenuWrapper: {
        display: 'flex',
        'align-items': 'center'
    },
    mobileMenuWrapper: {
        position: 'fixed',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        'z-index': '9999',
        background: 'rgba(0, 0, 0, 0.85)',
        'backdrop-filter': 'blur(5px)'
    },
    closeLabelText: {
        'font-family': 'Gilroy, sans-serif',
        'font-weight': '500',
        'font-size': '10px',
        'line-height': '13px',
        'letter-spacing': '0.1em',
        'text-transform': 'uppercase',
        color: variable.yellowColor
    },
    mobileMenuHeader: media(width, height, 'w', {
        1320: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '32px 0',
            margin: '0 -2px'
        },
        768: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '16px 0',
            margin: '0 -2px'
        },
        0: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '13px 0',
            margin: '0 -2px'
        }
    }),
    mobileMenuBorder: media(width, height, 'w', {
        768: {
            'border-left': '2px solid rgba(255, 255, 255, 0.1)',
            margin: '0 32px',
            height: '100vh'
        },
        0: {
            'border-left': '2px solid rgba(255, 255, 255, 0.1)',
            margin: '0 16px',
            height: '100vh'
        }
    }),
    mobileMenuLinks: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center'
    },
    authButtons: { 'margin-top': '64px' },
    socialWrapper: media(width, height, 'w', {
        768: {
            display: 'flex',
            'max-width': '228px',
            'min-width': '228px',
            'align-items': 'center',
            'justify-content': 'space-between'
        },
        0: {
            display: 'flex',
            'max-width': '176px',
            'min-width': '176px',
            'align-items': 'center',
            'justify-content': 'space-between',
            'margin': '24px 0 66px 0'
        }
    }),
    socialLinkWrapper: media(width, height, 'w', {
        768: {
            height: '20px',
            fill: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer',
            display: 'flex',
            'max-width': '228px',
            'align-items': 'center',
            'justify-content': 'space-between'
        },
        0: {
            height: '16px',
            fill: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer',
            display: 'flex',
            'max-width': '176px',
            'align-items': 'center',
            'justify-content': 'space-between'
        }
    }),
    socialLinkWrapperHover: { fill: 'rgba(164, 179, 202, 1)' },
    socialLinkSvg: media(width, height, 'w', {
        768: {
            width: '20px',
            height: '20px',
            fill: 'inherit'
        },
        0: {
            width: '16px',
            height: '16px',
            fill: 'inherit'
        }
    }),
    devLink: media(width, height, 'w', {
        768: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '700',
            'font-size': '14px',
            'line-height': '20px',
            color: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer',
            'margin-left': '5px'
        },
        0: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '700',
            'font-size': '12px',
            'line-height': '17px',
            'text-align': 'center',
            color: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer',
            'margin-left': '5px'
        }
    }),
    footerCompany: media(width, height, 'w', {
        768: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '500',
            'font-size': '14px',
            'line-height': '20px',
            'text-align': 'center',
            color: 'rgba(96, 110, 132, 1)'
        },
        0: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '500',
            'font-size': '12px',
            'line-height': '20px',
            'text-align': 'center',
            color: 'rgba(96, 110, 132, 1)'
        }
    }),
    footerLinksWrapper: {
        'text-align': 'center'
    },
    devLinkHover: { color: 'rgba(164, 179, 202, 1)' },
    agreementLink: media(width, height, 'w', {
        991: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '700',
            'font-size': '14px',
            'line-height': '20px',
            color: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer'
        },
        768: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '700',
            'font-size': '14px',
            'line-height': '20px',
            color: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer',
            'margin-bottom': '8px'
        },
        0: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '700',
            'font-size': '12px',
            'line-height': '17px',
            'text-align': 'center',
            color: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer',
            'margin-bottom': '8px'
        }
    }),
    companyWrapper: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'flex-wrap': 'wrap',
            'max-width': '65%',
            margin: '0 auto 24px auto',
            'justify-content': 'center'
        }
    }),
    lifeupBlock: media(width, height, 'w', {
        1144: {
            margin: '0'
        },
        991: {
            margin: '24px 0'
        },
        768: {
            margin: '24px 0 0 0'
        },
        0: {
            margin: '0 auto 24px auto',
        }
    }),
    slash: media(width, height, 'w', {
        768: {
            display: 'block',
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '500',
            'font-size': '14px',
            'line-height': '20px',
            'text-align': 'center',
            color: 'rgba(96, 110, 132, 1)',
            margin: '0 5px'
        },
        0: { display: 'none' }
    }),
    footerUpperWrapper: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'space-between',
            'margin-bottom': '42px',
            position: 'relative',
            'z-index': '150'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            position: 'relative',
            'z-index': '150'
        }
    }),
    footerWrapper: media(width, height, 'w', {
        1384: {
            padding: '0',
            'max-width': '1320px',
            width: '100%',
            'margin': '0 auto',
            position: 'relative'
        },
        768: {
            padding: '0 32px',
            'max-width': '1320px',
            width: '100%',
            'margin': '0 auto',
            position: 'relative'
        },
        0: {
            padding: '0 16px',
            position: 'relative'
        }
    }),
    footerLower: media(width, height, 'w', {
        1144: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'space-between',
            position: 'relative',
            'z-index': '150'
        },
        768: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            position: 'relative',
            'z-index': '150'
        },
        0: {
            position: 'relative',
            'z-index': '150'
        }
    }),
    agreementLinkMargin: media(width, height, 'w', { 991: { 'margin-right': '16px' } }),
    footerLowerAgreements: media(width, height, 'w', {
        991: {
            'margin-top': '0',
            display: 'flex',
            'align-items': 'center'
        },
        768: {
            'margin-top': '24px',
            'text-align': 'center'
        }
    }),
    agreementLinkHover: { color: 'rgba(164, 179, 202, 1)' },
    footerDown: {
        
    }
});
export default stylesheets;
